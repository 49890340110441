import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { userLoginReducer } from './reducers/userReducers';
import { agentReducer } from './reducers/agentReducer';
import { userAzureReducer } from './reducers/azureReducer';
import { contactMeReducer, sendStartAQuoteReducer } from './reducers/mailReducers';


const reducer = combineReducers({
	userLogin: userLoginReducer,
	contactMe: contactMeReducer,
	sendStartAQuote: sendStartAQuoteReducer,
	agentList: agentReducer,
	azureLogin: userAzureReducer
});

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
const azureInfoFromStorage = localStorage.getItem('az') ? JSON.parse(localStorage.getItem('az')) : null;

const initialState = {
	userLogin: { userInfo: userInfoFromStorage },
	azureLogin: { userInfo: azureInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
