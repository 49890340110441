import React from "react";
import Select from 'react-select';

const MultiSelectDropdown = (props) =>{
    return(
        <Select
        placeholder={props.placeHolder ? props.placeHolder : 'Select'} 
        styles={props.styles} 
        isMulti 
        className={props.className ? props.className : "multi_dropdown_start_a_quote"  } 
        options={props.options} 
        onChange={props.onChange} 
        />
    )
}

export default MultiSelectDropdown;