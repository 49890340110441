import React from 'react';
import { Link } from 'react-router-dom';
import Content from '../../../components/Content/Content';
import { NavLink } from 'react-router-dom';
import './LearningCenter.css';

const LearningCenter = () => {
	const items = [
		{
			id: 1,
			name: 'Strengthen Your Mind',
			date: 'September 27, 2021',
			image: '/images/StrengthenYourMind.webp'
		},
		{
			id: 2,
			name: 'Travelling on Budget',
			date: 'September 13, 2021',
			image: '/images/TravellingonBudget.webp'
		},
		{
			id: 3,
			name: 'Taking steps to have a better day',
			date: 'August 27, 2021',
			image: '/images/Takingstepstohaveabetterday.webp'
		},
		{
			id: 4,
			name: 'Insuring a Guest Ranch',
			date: 'August 16, 2021',
			image: '/images/InsuringaGuestRanch.webp'
		},
		{
			id: 5,
			name: 'Helpful Tips for Student Drivers',
			date: 'July 26, 2021',
			image: '/images/HelpfulTipsforStudentDrivers.webp'
		},
		{
			id: 6,
			name: 'Hail Preperations for your Home',
			date: 'August 6, 2021',
			image: '/images/HailPreperationsforyourHome.webp'
		}
	];
	return (
		<Content headerFooterMenu="mountain-west">
			<div className="mwfbi_banner2 main-content-padding">
				<h3 className="mwfbi_banner__heading--black heading_1">The Learning Center</h3>
				<h1 className="mwfbi_banner__text--black">The more interesting side of insurance information!</h1>
			</div>
			<div className="learning_content">
				<div className="learning__filters">
					<button className="learning__filters--btn heading_3 learning__filters--active">All</button>
					<NavLink
						className="learning__filters--btn heading_3"
						to="/360/auto-insurance"
						style={{ textDecoration: 'none' }}
					>
						Auto
					</NavLink>
					<NavLink
						to="/360/business-insurance"
						style={{ textDecoration: 'none' }}
						className="learning__filters--btn heading_3"
					>
						Business
					</NavLink>
					<NavLink to="" style={{ textDecoration: 'none' }} className="learning__filters--btn heading_3">
						Events
					</NavLink>
					<NavLink to="" style={{ textDecoration: 'none' }} className="learning__filters--btn heading_3">
						Family
					</NavLink>
					<NavLink
						to="/360/farm-&-ranch-insurance"
						style={{ textDecoration: 'none' }}
						className="learning__filters--btn heading_3"
					>
						Farm & Ranch
					</NavLink>
					<NavLink
						to="/360/other-offerings"
						style={{ textDecoration: 'none' }}
						className="learning__filters--btn heading_3"
					>
						Financial
					</NavLink>
					<NavLink to="" style={{ textDecoration: 'none' }} className="learning__filters--btn heading_3">
						Gardening
					</NavLink>
					<NavLink to="" style={{ textDecoration: 'none' }} className="learning__filters--btn heading_3">
						Health
					</NavLink>
					<NavLink to="" style={{ textDecoration: 'none' }} className="learning__filters--btn heading_3">
						Holiday
					</NavLink>
					<NavLink
						to="/360/home-owners-insurance"
						style={{ textDecoration: 'none' }}
						className="learning__filters--btn heading_3"
					>
						Homeowners
					</NavLink>
					<NavLink
						to="/360/life-insurance"
						style={{ textDecoration: 'none' }}
						className="learning__filters--btn heading_3"
					>
						Life & Retirement
					</NavLink>
					<NavLink to="" style={{ textDecoration: 'none' }} className="learning__filters--btn heading_3">
						Safety
					</NavLink>
					<NavLink to="" style={{ textDecoration: 'none' }} className="learning__filters--btn heading_3">
						Seasonal
					</NavLink>
					<NavLink to="" style={{ textDecoration: 'none' }} className="learning__filters--btn heading_3">
						Students
					</NavLink>
				</div>

				<div className="learning_content__cards">
					{items &&
						items.map((item) => (
							<Link
								key={item.id}
								to={`/360/about/learning-center/${item.name}`}
								className="learning_content__card"
								style={{
									backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.4)), url(${item.image}) `
								}}
							>
								<div className="learning_content__card--date heading_4">
									<svg className="learning_content__card--date_icon">
										<use xlinkHref="/sprite.svg#icon-access_time" />
									</svg>
									{item.date}
								</div>
								<div className="learning_content__card--title heading_3">{item.name}</div>
							</Link>
						))}
				</div>
			</div>
		</Content>
	);
};

export default LearningCenter;
