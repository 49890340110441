import {
	SEND_MAIL_FAIL,
	SEND_MAIL_REQUEST,
	SEND_MAIL_RESET,
	SEND_MAIL_SUCCESS,

	SEND_START_A_QUOTE_FAIL,
	SEND_START_A_QUOTE_REQUEST,
	SEND_START_A_QUOTE_RESET,
	SEND_START_A_QUOTE_SUCCESS
} from '../constants/mailConstants';


export const sendStartAQuoteReducer = (state = {}, action) => {
	switch (action.type) {
		case SEND_START_A_QUOTE_REQUEST:
			return { loading: true };

		case SEND_START_A_QUOTE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case SEND_START_A_QUOTE_FAIL:
			return { loading: false, error: action.payload };

		case SEND_START_A_QUOTE_RESET:
			return {};

		default:
			return state;
	}
};


export const contactMeReducer = (state = {}, action) => {
	switch (action.type) {
		case SEND_MAIL_REQUEST:
			return { loading: true };

		case SEND_MAIL_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case SEND_MAIL_FAIL:
			return { loading: false, error: action.payload };

		case SEND_MAIL_RESET:
			return {};

		default:
			return state;
	}
};
