import React, { Fragment } from 'react';

const FormInputComponent = ({
	heading,
	subheading = '',
	height,
	type,
	style,
	required = false,
	requiredStar = false,
	placeholder,
	value,
	onChange,
	disabled = false,
	errorMessage
	// key = 'Key' + (Math.floor(Math.random() * 10000))
}) => {
	const getInputDate = (date) => {
		try {
			let today = new Date(date);
			// let dd = today.getDate();
			let dd = String(today.getDate()).padStart(2, '0');
			let mm = String(today.getMonth() + 1).padStart(2, '0');
			let yyyy = today.getFullYear();

			return mm + '-' + dd + '-' + yyyy;
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div id={'component' + subheading + heading} style={style}>
			<label id={'label' + subheading + heading} htmlFor={subheading + heading} className='label_heading_quote'>
				{heading}
				{(required || requiredStar) && <span className='red_star'>&#42;</span>}
			</label>
			<div id={'componentHead' + subheading + heading} >
				{type !== 'date' ? (type === 'textarea' ?
					<textarea
						id={subheading + heading}
						rows="10"
						cols="30"
						disabled={disabled}
						required={required}
						placeholder={placeholder}
						style={{ height: height ? height : '4rem', padding: 10 }}
						value={value}
						onChange={onChange}
						className='text-input-prefix__formgroup--inputBox'
					/> : <input
						id={subheading + heading}
						type={type}
						// key={key}
						disabled={disabled}
						required={required}
						placeholder={placeholder}
						style={{ height: height ? height : '4rem' }}
						value={value}
						onChange={onChange}
						className='text-input-prefix__formgroup--inputBox'
					/>
				) : (
					<div id={'componentHeadDate' + subheading + heading} className='text-input-prefix__formgroup--dateBox'>
						<label id={subheading + heading + 'id'} for={subheading + heading} style={{ height: height ? height : '4rem', zIndex: '999' }}>
							{value ? getInputDate(value) : 'mm-dd-yyyy'}
							<svg>
								<use xlinkHref='/sprite.svg#icon-calendar' />
							</svg>
						</label>
						<input
							id={subheading + heading}
							name={subheading + heading}
							type={type}
							// value="2018-07-22"
							// min="2018-01-01" 
							// max="2018-12-31"
							// key={key}
							required={required}
							placeholder={placeholder}
							style={{ width: '100%', position: 'absolute',  zIndex: '1000', flexDirection: 'row'}}
							
							onChange={onChange}
							className='text-input-prefix__formgroup--inputBox text-input-prefix__formgroup--inputBox--date'
						/>
					</div>
				)}
				{!errorMessage && <p id={'errorlable' + subheading + heading} className='label_heading2'>{subheading}</p>}
			</div>
			{errorMessage && <p id={'lableerr' + subheading + heading} className='form_input__error'>{errorMessage}</p>}
		</div>
	);
};
export default FormInputComponent;
