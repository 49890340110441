import React, { Fragment, useEffect } from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'

import LoginScreen from './screens/LoginScreen'
import HomeScreen from './screens/HomeScreen/HomeScreen'
import AboutScreen from './screens/AboutScreen/AboutScreen'
import MWFBIHomeScreen from './screens/MWFBI/HomeScreen/HomeScreen'
import FarmBureauFederation from './screens/MWFBI/FarmBureauFederation'
import MWFBIAboutScreen from './screens/MWFBI/AboutScreen/AboutScreen'
import Faq from './screens/MWFBI/FAQ'
import Careers from './screens/MWFBI/Careers'
import LearningCenter from './screens/MWFBI/LearningCenter'
import LearningCenterDetails from './screens/MWFBI/LearningCenter/LearningCenterDetails'
import MWFBIHowToFileClaimScreen from './screens/MWFBI/FileClaim/HowToFileClaimScreen'
import FindAnAgent from './screens/MWFBI/FindAnAgent/FindAnAgent'
import FindAnAgentDetail from './screens/MWFBI/FindAnAgent/FindAnAgentDetail'
import StartAQuote from './screens/MWFBI/StartAQuote/StartAQuote'
import OurCommunity from './screens/MWFBI/OurCommunity/OurCommunity'
import AboutUs from './screens/MWFBI/AboutUs/AboutUs'
import AgentDescription from './screens/MWFBI/FindAnAgent/AgentDescription'
import SendUsMessage from './screens/MWFBI/SendUsMessage'

//phase 2
import AutoInsurance from './screens/MWFBI/AutoInsurance/index'
import BusinessInsurance from './screens/MWFBI/BusinessInsurance/index'
import HomeOwnersInsurance from './screens/MWFBI/HomeOwnersInsurance/index'
import Annuities from './screens/MWFBI/Annuities'
import LifeInsurance from './screens/MWFBI/LifeInsurance/index'
import OtherOfferings from './screens/MWFBI/OtherOfferings/index'
import FarmRanchInsurance from './screens/MWFBI/Farm&RanchInsurance'
import _360HomeScreen from './screens/360Insurance/HomeScreen/HomeScreen'
import ContactUs from './screens/MWFBI/ContactUs/ContactUs'

import AgentLogin from './screens/MWFBI/AgentLogin';
import AgentLandingPage from './screens/MWFBI/Landing/AgentLandingScreenLatest';
import CustomerLandingPage from './screens/MWFBI/Landing/CustomerLandingScreen';
import CustomerLogin from './screens/MWFBI/CustomerLogin'

// import GolfHomeScreen from './screens/Golf/HomeScreen/HomeScreen';
// import Registration from './screens/Golf/Registration';
// import Registration2 from './screens/Golf/Registration/Registration2';
// import Registration3 from './screens/Golf/Registration/Registration3';

// import BroncosRaffle from './screens/Golf/BroncosRaffle';
// import BroncosRaffle2 from './screens/Golf/BroncosRaffle/BroncosRaffle2';
// import Sponsers from './screens/Golf/Sponsers';

// import SalesHomeScreen from './screens/SalesMarketing/SalesHomeScreen/SalesHomeScreen';
// import StoreHomeScreen from './screens/Store/HomeScreen/HomeScreen';
// import ProductList from './screens/Store/ProductList/ProductList';
// import ProductDetail from './screens/Store/ProductDetail';

// import MyCartScreen from './screens/SalesMarketing/MyCart/MyCart';
// import SalesTemplate from './screens/SalesMarketing/SalesTemplate/SalesTemplate';
// import SalesTemplateDetails from './screens/SalesMarketing/SalesTemplate/SalesTemplateDetails';
// import BrochureTemplate from './screens/SalesMarketing/SalesTemplate/BrochureTemplate';
// import OurTeam from './screens/SalesMarketing/OurTeam/OurTeam';
// import CheckOut2 from './screens/SalesMarketing/CheckOut/CheckOut2';
// import CheckOut from './screens/SalesMarketing/CheckOut/CheckOut';
// import CheckOut3 from './screens/SalesMarketing/CheckOut/CheckOut3';
// import ContactUsScreen from './screens/SalesMarketing/ContactUs/ContactUs';
// import MarketingResources from './screens/SalesMarketing/MarketingResources/MarketingResources';

import './App.css'
import ERS from './screens/MWFBI/ERS/ERS'
import ReactGA from 'react-ga'

// import RadioScript from './screens/SalesMarketing/RadioScript';
// import HomeScreen360 from './screens/360Insurance/HomeScreen/HomeScreen';
// import MeetingHomeScreen from './screens/Meeting/HomeScreen/HomeScreen';
// import Annuities from './screens/MWFBI/Annuities';
import { isMobile } from 'react-device-detect'
import TermsConditions from './screens/MWFBI/TermsConditions/TermsConditions'
import PrivacyPolicy from './screens/MWFBI/PriavcyPolicy/PrivacyPolicy'
import Disclosure from './screens/MWFBI/Disclosure/Disclosure'
import { useDispatch } from 'react-redux'
import { getAgentList } from './redux/actions/agentListActions'

import InternalLandingScreen from './screens/MWFBI/InternalLanding'
import { USER_AZURE_LOGOUT, USER_AZURE_SUCCESS } from './redux/constants/userConstants'
const TRACKING_ID = 'UA-60364536-1' // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID)
const App = () => {

  const dispatch = useDispatch()
  let scaleValue = 1
  if (isMobile) document.body.style.zoom = 1
  else {
  }

  document.body.style.zoom = scaleValue
  document.body.style['-ms-zoom'] = scaleValue
  document.body.style['-webkit-zoom'] = scaleValue
  document.body.style['-ms-zoom'] = scaleValue

  // if (window.devicePixelRatio !== 1) {
  // 	let scaleValue =  (1 / window.devicePixelRatio);
  // 	if (isMobile)
  // 		document.body.style.zoom = 1
  // 	else {
  // 		document.body.style.zoom = scaleValue
  // 		document.body.style['-ms-zoom'] = scaleValue
  // 		document.body.style['-webkit-zoom'] = scaleValue
  // 		document.body.style['-ms-zoom'] = scaleValue
  // 	}
  // }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  useEffect(() => {
    dispatch(getAgentList())
    if (process.env.REACT_APP_NODE_ENV === 'development' && process.env.REACT_APP_ENABLE_EDIT === 'YES') {
      dispatch({
        type: USER_AZURE_SUCCESS,
        payload: { account: { username: 'piyoosh@mwfbi.com', accessToken: 'token', name: 'Developer' } }
      });
    } else if (process.env.REACT_APP_NODE_ENV === 'development') {
      dispatch({ type: USER_AZURE_LOGOUT });
    }
  }, [])

  return (
    <Fragment>
      <Router>
        <Route exact path='/main' component={HomeScreen} />
        <Route exact path='/about' component={AboutScreen} />
        <Route exact path='/360/find-an-agent' component={FindAnAgent} />
        <Route exact path='/360/agent-login' component={AgentLogin} />
        <Route exact path='/360/agent-hub' component={AgentLandingPage} />
        <Route exact path='/360/customer-landing' component={CustomerLandingPage} />
        <Route exact path='/360/customer-login' component={CustomerLogin} />
        <Route exact path='/360/find-an-agent-detail' component={FindAnAgentDetail} />
        <Route exact path='/' component={MWFBIHomeScreen} />
        <Route exact path='/login' component={LoginScreen} />
        <Route exact path='/360/about' component={MWFBIAboutScreen} />
        <Route exact path='/360/how-to-claim-file' component={MWFBIHowToFileClaimScreen} />
        <Route exact path='/360/ers' component={ERS} />
        <Route exact path='/360/faq' component={Faq} />
        <Route exact path='/360/careers' component={Careers} />
        <Route exact path='/360/learning-center' component={MWFBIHomeScreen} />
        <Route exact path='/360/farm-bureau-federation' component={FarmBureauFederation} />
        <Route exact path='/360/start-a-quote/:id?' component={StartAQuote} />
        <Route exact path='/360/about/our-community' component={OurCommunity} />
        <Route exact path='/360/about/careers' component={Careers} />
        <Route exact path='/360/about/learning-center' component={LearningCenter} />
        <Route exact path='/360/about/learning-center/:id' component={LearningCenterDetails} />
        <Route exact path='/360/about/farm-bureau-federation' component={FarmBureauFederation} />
        <Route exact path='/360/about-us' component={AboutUs} />
        <Route exact path='/360/send-us-message' component={SendUsMessage} />
        <Route exact path='/360/agent-details/:id' component={AgentDescription} />

        {/* phase 2 */}
        <Route exact path='/360/auto-insurance' component={AutoInsurance} />
        <Route exact path='/360/business-insurance' component={BusinessInsurance} />
        <Route exact path='/360/annuities' component={Annuities} />
        <Route exact path='/360/home-owners-insurance' component={HomeOwnersInsurance} />
        <Route exact path='/360/life-insurance' component={LifeInsurance} />
        <Route exact path='/360/other-offerings' component={OtherOfferings} />
        <Route
          exact
          path='/360/farm-&-ranch-insurance'
          component={FarmRanchInsurance}
        />

        <Route exact path='/360/contact' component={ContactUs} />
        <Route exact path='/360/TermsConditions' component={TermsConditions} />
        <Route exact path='/360/PrivacyPolicy' component={PrivacyPolicy} />
        <Route exact path='/360/disclosure' component={Disclosure} />

        
        <Route exact path='/360/internal-landing' component={InternalLandingScreen} />

        {/* <Route exact path="/golf" component={GolfHomeScreen} />
				<Route exact path="/golf/register" component={Registration} />
				<Route exact path="/golf/register-2" component={Registration2} />
				<Route exact path="/golf/register-3" component={Registration3} />
				<Route exact path="/golf/broncos-raffle" component={BroncosRaffle} />
				<Route exact path="/golf/broncos-raffle-2" component={BroncosRaffle2} />
				<Route exact path="/golf/sponsers" component={Sponsers} /> */}

        {/* <Route exact path="/sales-marketing" component={SalesHomeScreen} />
				<Route exact path="/sales-marketing/cart" component={MyCartScreen} />
				<Route exact path="/sales-marketing/sales-template" component={SalesTemplate} />
				<Route exact path="/sales-marketing/sales-product-details" component={SalesTemplateDetails} />
				<Route exact path="/sales-marketing/brochures" component={BrochureTemplate} />
				<Route exact path="/sales-marketing/ourteam" component={OurTeam} />
				<Route exact path="/sales-marketing/contact" component={ContactUsScreen} />
				<Route exact path="/sales-marketing/checkout" component={CheckOut} />
				<Route exact path="/sales-marketing/checkout2" component={CheckOut2} />
				<Route exact path="/sales-marketing/checkout3" component={CheckOut3} />
				<Route exact path="/sales-marketing/radio-script" component={RadioScript} />
				<Route exact path="/sales-marketing/marketing-resources" component={MarketingResources} /> */}
        {/*
				<Route exact path="/store" component={StoreHomeScreen} />
				<Route exact path="/store/product-list" component={ProductList} />
				<Route exact path="/store/product-detail" component={ProductDetail} /> */}

        {/* <Route exact path="/360-insurance/home" component={HomeScreen360} /> */}

        {/* <Route exact path="/meeting/:page?" component={MeetingHomeScreen} /> */}
      </Router>
    </Fragment>
  )
}

export default App
