import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PaymentModal from '../../screens/MWFBI/HomeScreen/PaymentModal';

import AccountModal from '../../screens/MWFBI/HomeScreen/AccountDetail';
import BillingModal from '../../screens/MWFBI/HomeScreen/BillingDetails';
import AgentMessageAlertModal from '../../screens/MWFBI/HomeScreen/AgentMessageAlert';
import axios from 'axios';
import './NewMenu.css';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from "../../authConfig";
import { azureLogout } from '../../redux/actions/userActions';
import { PAYMENT_BASE_URL, PAYMENT_US_URL, PORTAL_PAYMENT_REDIRECTION } from '../../redux/actions/ip';
import LoginTooltip from './LoginTooltip';

const NewMenu = ({ history }) => {
	const dispatch = useDispatch();
	const [userName, setUserName] = useState(null);
	const [checked, setChecked] = useState('checked1');

	const [paymentModal, setPaymentModal] = useState(false);
	const [accountModal, setAccountModal] = useState(false);
	const [billingModal, setBillingModal] = useState(false);
	const [paymentMessageModal, setPaymentMessageModal] = useState(false);
	const [accountDetails, setAccountDetails] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState('quick');
	const [alertMessage, setAlertMessage] = useState(null);
	const [showTooltip, setShowTooltip] = useState(false);

	const { instance } = useMsal();
	const userLogin = useSelector((state) => state.userLogin);

	const azureLogin = useSelector((state) => state.azureLogin);

	const toggleButton = () => {
		setShowTooltip(false)
		const exoMenu = document.querySelector('.exo-menu');
		exoMenu.classList.toggle('display');
	};
	const toggleButton1 = () => {
		const exoMenu = document.querySelector('.exo-menu');
		exoMenu.classList.toggle('display');
	};
	const logout = (e) => {
		e.preventDefault()
		instance.logoutPopup(loginRequest)
			.then(res => {
				dispatch(azureLogout())
			})
			.catch(e => {
				console.log(e);
			});
	}
	useEffect(
		() => {
			if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.status &&
				userLogin.userInfo.result &&
				userLogin.userInfo.result.accessToken
			) {
				setUserName(userLogin.userInfo.result.username);
			}
		},
		[userLogin]
	);
	useEffect(
		() => {
			// console.log(azureLogin);
			if (
				azureLogin &&
				azureLogin.userInfo &&
				azureLogin.userInfo.account
			) {
				setUserName(azureLogin.userInfo.account.name);
				if (history)
					history.push('/');
			}
		},
		[azureLogin, dispatch]
	);
	useEffect(
		() => {
			// console.log('Account', accountDetails);
			if (accountDetails) {
				setAccountModal(false)
				setBillingModal(true)
			}
		},
		[accountDetails]
	);

	const handleTooltip = () => {
		setShowTooltip((prevState) => !prevState)
	}

	const schedulePayment = async (data, callback) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			
			const accountDetail = await axios.post(`${PAYMENT_BASE_URL}/auth/get-token/${data.referenceNumber}/${data.minAmountDue}`, { data1: "hi", data }, config);
			// console.log('==>>', accountDetail.data);
			if (accountDetail.data.status) {
				let container = window.$('#portalOneContainer');
				//instantiate modal
				container.portalOne();
				let portalOne = container.data('portalOne');
				portalOne.run({
					sessionId: accountDetail.data.sessionKey,
					accessTokenId: accountDetail.data.accessToken,
					displayMode: 'Modal',
					allowClosing: 'true'
				});
				container
					.addEventListener("portalOne.scheduledPaymentCreated", function () {
						console.log(new Date() + ' portalOne.scheduledPaymentCreated');
					});
				container
					.addEventListener("portalOne.scheduledPaymentCanceled", function () {
						console.log(new Date() + ' portalOne.scheduledPaymentCanceled');
					});
				// callback(true)
				return Promise.resolve(accountDetail.data)
			} else {
				// callback(false)
				return Promise.resolve(accountDetail.data)
			}
		} catch (error) {

			return Promise.reject(error)

		}
	}
	const quickPay = async (data) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			const accountDetail = await axios.post(`${PAYMENT_BASE_URL}/auth/quick-pay-token/${data}`, {
				data1: "hi", accountNumber: data, isAgent: (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account)
			}, config);
			// console.log('==>>', accountDetail.data);
			if (accountDetail.data.status) {
				if (accountDetail.data.isAgent) {
					let container = window.$('#portalOneContainer');
					container.portalOne();
					let portalOne = container.data('portalOne');
					portalOne.run({
						sessionId: accountDetail.data.sessionKey,
						accessTokenId: accountDetail.data.accessToken,
						displayMode: 'Modal',
						allowClosing: 'true'
					});
				} else {
					setAlertMessage(accountDetail.data.message)
					setPaymentMessageModal(true)
				}
				// callback(true)
				return Promise.resolve(accountDetail.data)
			} else {
				// callback(false)
				if (accountDetail.data.redirect) {
					const win = window.open(process.env.REACT_APP_PAY_BILL_URL, '_blank');
					if (win != null) {
						win.focus();
					}
					return Promise.resolve(true)
				} else
					return Promise.resolve(accountDetail.data)
			}
		} catch (error) {

			return Promise.reject(error)

		}
	}
	useEffect(() => {
		let subMenuLink = document.getElementsByClassName('exo-menu__head');

		if (subMenuLink) {
			for (let i = 0; i < subMenuLink.length; i++) {
				if (subMenuLink[i].parentElement.className === 'mega-drop-down') {
					subMenuLink[i].href = 'javascript:void(1)';

					subMenuLink[i].addEventListener('click', function (e) {
						e.preventDefault();
						this.classList.toggle('dropdown-visible');
					});
					// console.log(subMenuLink[i].nextSibling, document.getElementsByClassName('mega-menu')[0]);
				}
			}
		}
	}, []);

	useEffect(() => {
		let dropDown = document.getElementsByClassName('drop-down_link');

		if (dropDown) {
			for (let i = 0; i < dropDown.length; i++) {
				if (dropDown[i].parentElement.className === 'drop-down') {
					dropDown[i].href = 'javascript:void(0)';

					dropDown[i].addEventListener('click', function (e) {
						e.preventDefault();
						this.classList.toggle('dropdown-visible');
					});
					// console.log(dropDown[i].nextSibling, document.getElementsByClassName('mega-menu')[0]);
				}
			}
		}
	}, []);

	return (
		<div className="megaMenu">
			<div className="menu">
				<div className="left__menu">
					<Link to="/" className="Logo_container">
						<img src="/images/logo-2.png" alt="Logo" className="logo" />
					</Link>
					<ul className="exo-menu">
						{/* <li className="mega-drop-down"> */}
						<li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)} className="drop-down">
							<NavLink
								to="/360/auto-insurance"
								className="drop-down_link"
								activeClassName="navigation__active"
							>
								Insurance
							</NavLink>
							<ul className="drop-down-ul fadeInAnimation">
								<li>
									<Link to="/360/auto-insurance">Auto</Link>
								</li>
								<li>
									<Link to="/360/home-owners-insurance">Home</Link>
								</li>
								<li>
									<Link to="/360/farm-&-ranch-insurance">Farm & Ranch</Link>
								</li>
								<li>
									<Link to="/360/business-insurance">Business</Link>
								</li>
								<li>
									<Link to="/360/life-insurance">Life Insurance</Link>
								</li>
								<li>
									<Link to="/360/annuities">Annuities</Link>
								</li>
								<li>
									<Link to="/360/other-offerings">Other</Link>
								</li>
							</ul>

						</li>

						<li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)} >
							<NavLink
								exact
								to="/360/find-an-agent"
								activeClassName="navigation__active"
								className="exo-menu__head"
							>
								Find an Agent
							</NavLink>
						</li>
						<li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)}>
							<NavLink
								exact
								to="/360/how-to-claim-file"
								activeClassName="navigation__active"
								className="exo-menu__head"
							>
								Claims
							</NavLink>
						</li>
						<li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)} className="drop-down">
							<NavLink
								to="#"
								className="drop-down_link"
								activeClassName="navigation__active"
							>
								About Us
							</NavLink>
							<ul className="drop-down-ul fadeInAnimation">

								<li>
									<Link to="/360/about-us">About Us</Link>
								</li>
								{/* <li>
									<Link to="/360/about/our-community">Our Community</Link>
								</li> */}
								{/*	<li>
									<Link to="/360/about/farm-bureau-federation">Farm Bureau Federation</Link>
								</li>
								<li>
									<Link to="/360/about/careers">Careers</Link>
								</li> */}
								<li>
									<Link to="/360/about/learning-center">Learning Center</Link>
								</li>
							</ul>
						</li>
						<li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)}>
							<NavLink
								exact
								to="/360/faq"
								activeClassName="navigation__active"
								className="exo-menu__head"
							>
								FAQ
							</NavLink>
						</li>
						<li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)}>
							<NavLink
								exact
								to="/360/start-a-quote"
								activeClassName="navigation__active"
								className="exo-menu__head"
							>
								Start a Quote
							</NavLink>
						</li>
						{azureLogin &&
							azureLogin.userInfo &&
							azureLogin.userInfo.account ?
							<li className="drop-down">
								<NavLink
									to="#"
									className="drop-down_link"
									activeClassName="navigation__active"
								>
									Payment
								</NavLink>
								<ul className="drop-down-ul fadeInAnimation" style={{ textAlign: 'left' }}>

									<li>

										<Link to="#" onClick={() => {
											setPaymentMethod('schedule')
											setAccountModal(true)
										}}>Get Account</Link>

									</li>
									
									<li>
										<Link to="#" onClick={() => {
											setPaymentMessageModal(true)
											setAlertMessage("Please contact your agent")
										}}>Quick Pay – OneInc</Link>
									</li>
									<li>
										<a href={process.env.REACT_APP_PORTAL_PAYMENT}
											target={'_blank'}>OneInc Wallet</a>
									</li>

								</ul>
							</li> :
							<li className="drop-down" style={{ textAlign: 'left' }} onClick={() => {
								handleTooltip();
								toggleButton1()
							}}>
								<NavLink
									to="#"
									className="drop-down_link"
									activeClassName="navigation__active"
								>
									Login/Payment
								</NavLink>
								<ul className="drop-down-ul fadeInAnimation" style={{ textAlign: 'left' }}>

									{/* <li>
										{azureLogin &&
											azureLogin.userInfo &&
											azureLogin.userInfo.account ?
											<Link to="#" onClick={() => {
												setPaymentMethod('schedule')
												setAccountModal(true)
											}}>Get Account</Link>
											:
											<Link to="/login" >Agent Login</Link>
										}
									
									</li>
									<li>
										<a rel="noreferrer" href={process.env.REACT_APP_PAY_BILL_URL}
											target={'_blank'}>Quick Pay – Paymentus</a>										
									</li>
									<li>
										<Link to="#" onClick={() => {
											setPaymentMessageModal(true)
											setAlertMessage("Please contact your agent")
										}}>Quick Pay – OneInc</Link>
									</li>
									<li>
										<a href={process.env.REACT_APP_PORTAL_PAYMENT}
											target={'_blank'}>OneInc Wallet</a>
									</li> */}

								</ul>
							</li>}
							{azureLogin &&
							azureLogin.userInfo &&
							azureLogin.userInfo.account && <li onClick={() => setShowTooltip(false)} onMouseOver={() => setShowTooltip(false)}>
								<NavLink
									exact
									to="/360/agent-hub"
									activeClassName="navigation__active"
									className="exo-menu__head"
								>
								360 Hub
								</NavLink>
							</li>}
						<div className='tooltipViewNaviagtion'>
						{showTooltip && <LoginTooltip setShowTooltip={setShowTooltip} onClick={() => setShowTooltip(true)} />}
						</div>
						{/* <li>
							<a
								href={process.env.REACT_APP_PAY_BILL_URL}
								target="_blank"
								rel="noreferrer"
								activeClassName="navigation__active"
								className="exo-menu__buttons_mobile--btn"
								style={{ textDecoration: 'none' }}
							>
								Quick Pay
							</a>
							

						</li> */}
						{azureLogin &&
							azureLogin.userInfo &&
							azureLogin.userInfo.account && <li><label className='logout_css'>{userName}</label></li>}
						{azureLogin &&
							azureLogin.userInfo &&
							azureLogin.userInfo.account && <li onClick={logout}><a href='target' className='logout_css'>Logout</a></li>}
						<div className="exo-menu__buttons_mobile">
							<PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} />
							<button onClick={toggleButton} href="#!" className="toggle-menu visible-xs-block">
								<div className="toggle-menu-line" />
								<div className="toggle-menu-line" />
								<div className="toggle-menu-line" />
								<div className="toggle-menu-line" />
							</button>
						</div>
					</ul>
				</div>
				<ul className="menu__right">
					{/* <li>
						<NavLink exact to='/login' activeClassName='navigation__active' className='navigation__link'>
							Login
						</NavLink>
					</li>
					<li>
						<NavLink exact to='/pay' activeClassName='navigation__active' className='navigation__link2'>
							Pay Bill
						</NavLink>
					</li> */}
					<PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} />
					<AgentMessageAlertModal setPaymentMessageModal={setPaymentMessageModal} paymentMessageModal={paymentMessageModal} message={alertMessage} />

					<AccountModal
						setPaymentMessageModal={setPaymentMessageModal}
						setAlertMessage={setAlertMessage}
						onQuickPayment={quickPay}
						setPaymentModal={setAccountModal}
						paymentMethod={paymentMethod}
						setAccountDetails={setAccountDetails}
						paymentModal={accountModal}
						onAccountDetails={(data) => {
							setAccountDetails(data);
						}} />
					<BillingModal
						setPaymentModal={setBillingModal}
						paymentModal={billingModal}
						accountDetail={accountDetails}
						schedulePayment={schedulePayment} />
					<div id="portalOneContainer" key="portalOneContainer" />
				</ul>
			</div>
			<div className='tooltipViewNaviagtion1'>
				{showTooltip && <LoginTooltip setShowTooltip={setShowTooltip} onClick={() => setShowTooltip(true)} />}
			</div>
		</div>
	);
};

export default NewMenu;
