import React from 'react';
import GoogleMapReact from 'google-map-react';

const GoogleMap = ({ defaultProps, setGoogleMapRef, children }) => {
    return (
        <GoogleMapReact
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
            options={{
                mapTypeControl: true,
                streetViewControl: true
            }}
        >
            {children}
        </GoogleMapReact>
    );
};

export default GoogleMap;
