import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Content from '../../../components/Content/Content';
import dataList from './data';
import ReactHtmlParser from 'html-react-parser';

const LearningCenterDetails = () => {
	const [relatedContents, setRelatedContents] = useState([
		{
			id: 1,
			name: 'Taking Steps to have a Better Day',
			image:
				'/images/travelingonbudgetdetails.webp',
			href: '/taking-steps-to-have-a-better-day/'
		},
		{
			id: 2,
			name: 'Winter Outdoor Activities',
			image: '/images/takignstepsdetails.webp',
			href: '/winter-outdoor-activities/'
		},
		{
			id: 3,
			name: 'Tips for Staying Active During a Pandemic',
			image: '/images/snowdetails.webp',
			href: '/tips-for-staying-active-during-a-pandemic/'
		}])
	const [data, setData] = useState({
		id: 1,
		name: 'Strengthen Your Mind',
		date: 'September 27, 2021',
		image: '/images/StrengthenYourMind.webp',
		tag: 'Health',
		tag1: 'mind,strengthen,tips',
		tagLine: 'Even though the brain is an organ, rather than a muscle, you can still give your brain a workout.',
		description: 'Just as with a muscle, repetitive tasks can dull or even damage your mental activity, while new challenges and activities can strengthen your brain and even make you measurably smarter.',
		footerLable: `Source:`,
		footerTitle: `9 Ways to Instantly Strengthen Your Brain`,
		footerLink: `https://www.americanexpress.com/en-us/business/trends-and-insights/articles/improve-memory-boost-brain-power/`,
		contentList: [
			{
				title: `Play Memory Games`,
				subTitle: `Whether it’s a board game or mobile-friendly games like Lumosity, actively working to improve your memory produces measurable results. Memory development really is key to not just feeling smarter, but truly being smart. Imagine if you could recall everything you have ever learned. That is probably impossible, but if you can train your mind to be able to recall a small portion of things you are currently forgetting, you will feel smarter and become more efficient in all your day-to-day activities.`
			},
			{
				title: `Raise Your Eyebrows`,
				subTitle: `You may feel silly, but as soon as you try this tip, you will understand exactly why it helps. Raising your eyebrows opens your eyes wider, sending your brain a slight adrenaline boost. You’ll instantly feel brighter and more alert.`
			},
			{
				title: `Practice Weaknesses`,
				subTitle: `While this may seem harmful to the mind there are actually scientific studies to prove it. If you’re a morning person who’s most productive and alert early in the day, try tackling a creative task late at night, and vice versa for night owls. It’s been discovered that when stress is on the brain can expand those parts of the mind. It is suggested to work these areas of your brain for creative tasks, rather than the more analytic tasks, and you’ll be surprised at what you can accomplish when you work at what isn’t your typically optimum time.`
			},
			{
				title: `Pick Up That Book You Would Never Read`,
				subTitle: `Reading is one of the best things you can do for the brain. Maybe you just commit to turning off the TV and pick up a book once in a while. Perhaps you branch out from your usual style of book. The point is to read something that’s different from your usual fare because if you broaden your reading horizons, you’re getting smarter. Swap your usual genre for something you wouldn’t normally read every once in a while. This could help you get out of your reading rut.`
			},
			{
				title: `Try New Hobbies`,
				subTitle: `Experiment with new enterprises to direct your focus and attention in a way that’s new to you. Mastering a new mechanical task develops new territory in your brain. Any new challenge you undertake will create new associates and force your brain to accommodate new information and new routines.`
			},
			{
				title: `Get Out and Exercise`,
				subTitle: `Exercise improves absolutely everything. Not only will you feel better if you exercise regularly, but you will improve circulation throughout your body, moving protein and nutrients to your brain. Remember that a strong body is a strong mind.`
			},
			{
				title: `Eat Healthy`,
				subTitle: `Organs require nourishment and you should strive for a balanced diet simply because a brain that is hungry isn’t growing. Supplements can help improve your concentration, improve your memory, and increase your attention span.`
			},
			{
				title: `Get Enough Sleep`,
				subTitle: `Proper sleep improves every facet of your life. Operating with a sleep deficit can actually be dangerous, and studies have shown that being sleep deprived can result in symptoms that mimic intoxication. One technique that can specifically increase your brain activity while you sleep is to eat a small snack before going to sleep. That snack will keep your mind more active while your body rests, that activity will make your brain grow stronger.<br/><br/>

                Thinking of your mind as a muscle is a great comparison, whether or not it's totally accurate. If you don’t exercise your muscles, they aren’t going to grow stronger. If you constantly use those muscles, they will grow stronger. The same goes for your mind. Challenge your brain in new ways as often as possible, and you’ll be stronger and smarter than you were the day before.`
			}
		]

	})
	const { id } = useParams();
	useEffect(() => {
		const test = dataList.find(item => item.name === id);
		setData(test)
	}, [])

	return (
		<Content headerFooterMenu="mountain-west">
			{data && (
				<Fragment>
					<div
						className="mwfbi_banner3 main-content-padding"
						style={{ backgroundImage: `url(${data.image})` }}
					>
						<h3 className="mwfbi_banner__heading2 heading_1">{data.name}</h3>
						{/* <h1 className='mwfbi_banner__text2'>The more interesting side of insurance information!</h1> */}
						<div className="mwfbi_banner__header">
							<p className="mwfbi_banner__header--date">
								<svg className="mwfbi_banner__header--date_icon">
									<use xlinkHref="/sprite.svg#icon-access_time" />
								</svg>
								{data.date}
							</p>
							<p className="mwfbi_banner__header--date">
								<svg className="mwfbi_banner__header--date_icon">
									<use xlinkHref="/sprite.svg#icon-edit-copy" />
								</svg>
								{data.tag}
							</p>
							<p className="mwfbi_banner__header--date">
								<svg className="mwfbi_banner__header--date_icon">
									<use xlinkHref="/sprite.svg#icon-tag" />
								</svg>
								{data.tag1}
							</p>
						</div>
					</div>

					<div className="blog__red_section">
						<p className="blog__red_section--text">
							{data.tagLine}
						</p>
					</div>
					<div className="blog__data">
						<p className="blog__data--text">
							{ReactHtmlParser(data.description)}
						</p>
						{data.contentList.map(content => (<div>
							<h4 className="blog__data--heading">{content.title}</h4>
							<p className="blog__data--text">
								{ReactHtmlParser(content.subTitle)}
							</p>
						</div>))}

						<div className="blog__data--source">
							<p>{data.footerLable}</p>
							<a href={data.footerLink}>
								{data.footerTitle}
							</a>
						</div>
					</div>
					<div className="blog__related">
						<h3 className="blog__related--title">Related Posts</h3>
						<div className="blog__related--posts">
							{relatedContents &&
								relatedContents.map((content) => (
									<a href={content.href} className="blog__related--post" key={content.id}>
										<img
											src={content.image}
											alt="Taking Steps to have a Better Day"
											className="blog__related--image"
										/>
										<h5 className="blog__related--heading">{content.name}</h5>
										<p className="blog__related--link">Read More</p>
									</a>
								))}
						</div>
					</div>
				</Fragment>
			)}
		</Content>
	);
};

export default LearningCenterDetails;
