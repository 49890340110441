
const data = [
    {
        id: 1,
        name: 'Strengthen Your Mind',
        date: 'September 27, 2021',
        image: '/images/StrengthenYourMind.webp',
        tag: 'Health',
        tag1: 'mind,strengthen,tips',
        tagLine: 'Even though the brain is an organ, rather than a muscle, you can still give your brain a workout.',
        description: 'Just as with a muscle, repetitive tasks can dull or even damage your mental activity, while new challenges and activities can strengthen your brain and even make you measurably smarter.',
        footerLable: `Source:`,
        footerTitle: `9 Ways to Instantly Strengthen Your Brain`,
        footerLink: `https://www.americanexpress.com/en-us/business/trends-and-insights/articles/improve-memory-boost-brain-power/`,
        contentList: [
            {
                title: `Play Memory Games`,
                subTitle: `Whether it’s a board game or mobile-friendly games like Lumosity, actively working to improve your memory produces measurable results. Memory development really is key to not just feeling smarter, but truly being smart. Imagine if you could recall everything you have ever learned. That is probably impossible, but if you can train your mind to be able to recall a small portion of things you are currently forgetting, you will feel smarter and become more efficient in all your day-to-day activities.`
            },
            {
                title: `Raise Your Eyebrows`,
                subTitle: `You may feel silly, but as soon as you try this tip, you will understand exactly why it helps. Raising your eyebrows opens your eyes wider, sending your brain a slight adrenaline boost. You’ll instantly feel brighter and more alert.`
            },
            {
                title: `Practice Weaknesses`,
                subTitle: `While this may seem harmful to the mind there are actually scientific studies to prove it. If you’re a morning person who’s most productive and alert early in the day, try tackling a creative task late at night, and vice versa for night owls. It’s been discovered that when stress is on the brain can expand those parts of the mind. It is suggested to work these areas of your brain for creative tasks, rather than the more analytic tasks, and you’ll be surprised at what you can accomplish when you work at what isn’t your typically optimum time.`
            },
            {
                title: `Pick Up That Book You Would Never Read`,
                subTitle: `Reading is one of the best things you can do for the brain. Maybe you just commit to turning off the TV and pick up a book once in a while. Perhaps you branch out from your usual style of book. The point is to read something that’s different from your usual fare because if you broaden your reading horizons, you’re getting smarter. Swap your usual genre for something you wouldn’t normally read every once in a while. This could help you get out of your reading rut.`
            },
            {
                title: `Try New Hobbies`,
                subTitle: `Experiment with new enterprises to direct your focus and attention in a way that’s new to you. Mastering a new mechanical task develops new territory in your brain. Any new challenge you undertake will create new associates and force your brain to accommodate new information and new routines.`
            },
            {
                title: `Get Out and Exercise`,
                subTitle: `Exercise improves absolutely everything. Not only will you feel better if you exercise regularly, but you will improve circulation throughout your body, moving protein and nutrients to your brain. Remember that a strong body is a strong mind.`
            },
            {
                title: `Eat Healthy`,
                subTitle: `Organs require nourishment and you should strive for a balanced diet simply because a brain that is hungry isn’t growing. Supplements can help improve your concentration, improve your memory, and increase your attention span.`
            },
            {
                title: `Get Enough Sleep`,
                subTitle: `Proper sleep improves every facet of your life. Operating with a sleep deficit can actually be dangerous, and studies have shown that being sleep deprived can result in symptoms that mimic intoxication. One technique that can specifically increase your brain activity while you sleep is to eat a small snack before going to sleep. That snack will keep your mind more active while your body rests, that activity will make your brain grow stronger.<br/>
\n
                Thinking of your mind as a muscle is a great comparison, whether or not it's totally accurate. If you don’t exercise your muscles, they aren’t going to grow stronger. If you constantly use those muscles, they will grow stronger. The same goes for your mind. Challenge your brain in new ways as often as possible, and you’ll be stronger and smarter than you were the day before.`
            }
        ]

    },
    {
        id: 2,
        name: 'Travelling on Budget',
        date: 'September 13, 2021',
        image: '/images/TravellingonBudget.webp',
        tag: 'Health, Life & Retirement',
        tag1: 'day, good, tips',
        tagLine: 'Traveling around the world doesn’t have to cost the lottery, not if you know how to watch your pennies. Here are some compiled, tried, and tested tips, to help you plan a holiday budget.',
        description: '',
        footerLable: ``,
        footerTitle: `More Travel Tips`,
        footerLink: `https://www.travelandleisure.com/travel-tips/editor-travel-tips-and-hacks`,
        contentList: [
            {
                title: `Come Up with a Plan`,
                subTitle: `Traveling at random is great, if you have the time and money to spare that come with it. But if you’re traveling on a budget, the first thing to do is come up with a plan. You don’t have to have an hour-by-hour itinerary, but you should at least have an idea of how long you’ll be spending in each new location and know the route that your new journey will take. Leaving less to chance means less unexpected expenses since last minute changes while traveling are more expensive.`
            },
            {
                title: `Travel Out of Season`,
                subTitle: `Avoid trips during school holidays, as this is when the travel companies raise their prices to take advantage of the traveling market that can only travel on those weeks. Research the best time to visit your intended destination, and then travel just before or after those days. Known as the “shoulder season” where you will be able to have great trip but won’t be there when the large numbers of people are also there. Hotels and airlines lower their prices to attract customers during this time as well, a great motivator.`
            },
            {
                title: `Be Accommodation Savvy`,
                subTitle: `Hotel costs can add up. If you are willing to experience hosting sources for less, but the sacrifice of sharing space with others, look into Airbnb’s, where you simply have to book a spare room in a local person’s property. It’ll halve the price and allow you to an authentic snapshot of real life in the place you are visiting. Consider your host your very own, personal tour guide, filled with insider information for the best eateries and tourist spots in your area.
<br/>
                You could even stay with family or friends. Reach out to people you know or plan a trip to somewhere that a long-lost cousin or school friend now lives. This could take you to visit places you’d never have thought to visit before.`
            },
            {
                title: `Pack Properly`,
                subTitle: `Make sure you bring everything you need so that you don’t have to shop for things you need while you are traveling. No matter where you are heading, take at least one pair of warm pants, warm top, and a waterproof jacket in case of changes in the weather.`
            },
            {
                title: `Book Flights in Advance`,
                subTitle: `Running out of money while traveling without a purchased ticket home is never ideal. Airlines release their flight seats up to a year in advance and the closer you get to your departure date, the more the prices increase, especially in the last month. Airlines say that the cheapest days to fly are the beginning of the week, the farthest away from the weekends the better. While that can be conflicting, there are also less people at the airport to deal with in the first place.
<br/>
                Flying economy will save you dollars, while business class looks tempting. Low cost, budget airlines are fine for the short flights and regularly have cheap deals. If you’re planning a weekend trip, try to pack light and use only hand luggage, saving you some money.`
            },
            {
                title: `Embrace Public Transport`,
                subTitle: `Buses and trains are cheaper than planes. A journey on an overnight train also means you have one less night in a hotel to pay for. For example, if you used a plane to get from New York to Boston you would be paying an average of $290.00, while if you chose to take the Amtrack you would be paying an average of $162.00. Plus, you may see more scenery and get to kick back a little more if you take the train route. To take a plane from Boston to Philadelphia, a plane costs an average of $300.00 a ticket, while taking the bus would be a simple $30.00. Remember to prepare your time differently though, as in most cases the cheaper options will take longer to get from point to point.`
            },
            {
                title: `Don’t Eat Away Your Cash`,
                subTitle: `Cheap lunches can be bought in a grocery store rather than in a dining facility. Consider looking at pricing and food in your area. Walk around and see what there is to offer before choosing a place for the trip. Additional drinks can add up, so consider just ordering water. If you are staying at a house, or even some hotel rooms, there may be a kitchen. Consider cooking instead of eating out.`
            },
            {
                title: `Earn While You Travel`,
                subTitle: `Working holidays may be awful in the moment, but most companies pay extra for working those days. While it doesn’t seem like it adds up, working for those extra sets of change can be the difference between staying at a questionable hotel and staying comfortably at a nice location. Another way to travel while making money is to look for jobs in the places that you want to travel. If you want to make a permanent job out of it that is up to you, but there are many jobs that are out there that are seasonal at beautiful places that may be looking for someone just like you!
<br/>
                Traveling is one of the best ways to spend time, but it isn’t unheard of for travel to be pricey. Using these tips while traveling will help maintain a budget, have some spare change left over, and still allow you to have a great time.`
            }
        ]
    },
    {
        id: 3,
        name: 'Taking steps to have a better day',
        date: 'August 27, 2021',
        image: '/images/Takingstepstohaveabetterday.webp',
        tag: 'Health, Life & Retirement',
        tag1: 'day, good, tips',
        tagLine: 'The actions we perform on a day-to-day basis determines who we are in the long run. In the spirit of pursuing fulfillment and joy, here are ways to lay the foundation to having a good day.',
        description: 'Annie Dillard said, “How we spend our days is, of course, how we spend our lives.” Her words are true for everyone living a day-to-day life, while also partaking in a day-to-day job. The actions we perform on a day-to-day basis determines who we are in the long run. In the spirit of pursuing fulfillment and joy, here are ways to lay the foundation to having a good day.',
        footerLable: ``,
        footerTitle: `More Tips for Having a Better Day`,
        footerLink: `https://www.thefrugalmillionaireblog.com/how-to-have-a-great-day/`,
        contentList: [
            {
                title: `Learn Something New`,
                subTitle: `Engaging your brain with new challenges and information on a day-to-day basis promotes healthy growth. Great ways to gain new knowledge daily is to listen to podcasts, read interesting articles, or simply reaching out to talk to someone in your contacts that you haven’t talked to in a while.`
            },
            {
                title: `Make Someone’s Day Better`,
                subTitle: `When you choose to reach out and help someone else it not only helps them have a better day, but yourself as well. It isn’t a myth when people say that it takes more energy to be hostile towards people than to be nice to people. No one knows everything about what other people are going through, so reaching out and making someone’s day better is always a great move to feel something rewarding in your day. It can be as simple as causing laughter, or creating a smile, as for that person it may be the peak of the day.`
            },
            {
                title: `Using Your Strengths`,
                subTitle: `Knowing your strengths and performing tasks that fit those strengths can allow you to feel proficient, accomplished, and confident. Work won’t feel like work, and you can excel at the things you do best! Research has shown that employees who get to use their strengths outperform those who don’t, because those employees feel more fulfilled.`
            },
            {
                title: `Fortify Yourself`,
                subTitle: `Actively deciding to eat well, exercise, and the proper amount of sleep can help create a platform to feeling better daily. Every bite of food, footstep taken, and hour of genuine sleep can have a significant impact on your overall physical and mental health. These acts give or take away energy, and its always best to supply yourself with healthy energy moving forward.`
            },
            {
                title: `Long Term Goals`,
                subTitle: `Do at least one thing a week at the very least to take you one step closer to creating your dream career or hobby. Nothing slows down the mind like feeling trapped where you don’t want to be. The feeling of moving forward with your goals and ambitions keeps the brain refreshed.`
            },
            {
                title: `Do Something Meaningful`,
                subTitle: `Do something, no matter how small, that somehow improves the world. When people think of doing something meaningful, they often think this means they have to donate copious amounts of money to charities, or attending multiple fundraisers or charity events, but that isn’t always the case. It may be as simple as picking up the plastic bags you see while walking the dogs every day, or to say hello to the one lone elderly lady that plants flowers at the park every day.`
            },
            {
                title: `Take a Moment for Yourself`,
                subTitle: `Spend, at minimum, 30 seconds reflecting on what you have accomplished and appreciating what you have. Acknowledging and remembering the great parts of your life up to that moment, is one of the greatest ways to reset the mind for the day ahead or the day that has passed.
<br/>
                In this competitive, ambitious world we are all doing our best to make our own and feel secure and content with our daily lives. Taking the steps to having better days can help life feel more under your control, rewarding, and can transform life into something that is worthy of looking forward to.`
            }
        ]
    },
    {
        id: 4,
        name: 'Insuring a Guest Ranch',
        date: 'August 16, 2021',
        image: '/images/InsuringaGuestRanch.webp',
        tag: 'Business, Seasonal',
        tag1: 'dude ranch, guest, ranch',
        tagLine: 'While the fantasy of the wild west is a wonderful one to the passing traveler, there are many insurance factors that the permanent dwellers of the property need to consider.',
        description: `Guest Ranches, otherwise known as Dude Ranches, are growing in popularity throughout the west. The vision of the wild west, the mystic idea that anyone could be a cowboy for a day, wrangling the old classic tale of the west from the back of your trusty stallion lives in the soul of many, and guest ranches allow for anyone to live out that feeling at least once in their life. While the fantasy of the wild west is a wonderful one to the passing traveler, there are many insurance factors that the permanent dwellers of the property need to consider. Consider some of the following insurance coverages if you own a guest ranch or are considering starting one of your own soon.
<br/><br/>
       <li> The first thing to consider is how many elements of the wild west are you wanting to provide? Do you want a working ranch experience, a buffalo bill wild west experience, or a modern relaxation getaway with horses grazing nearby? Regardless of what aesthetic you want for your property, there are elements that have to be decided. Will you have horseback riding? Hiking? A pool? Do you offer dining and housing? Are there any other activities offered at your property such as fishing or sports focused areas of acreage? All of these elements could spring a new area of insurance that someone may not think of without knowing about their available options.</li><br/><br/>
        <li>Many guest ranches, arguably most, offer horseback riding in one form or another. Whether it’s a petting zoo, trail rides, wagon rides, or assisted cattle wresting, insurance is highly suggested. Many legal firms would argue that it is necessary. Outside of legal waives, another way to keep your company safe from financial downfall in the state of a horse related accident would be general liability insurance, errors, and omissions insurance. Both of these options allow coverage to the clients that are injured while riding along on your animals. This could also help insure you if you were to have an accident. Another set of people to care for are your employees. Worker’s compensation could help cover the injury of your employees, while still helping your business stay afloat afterwards.</li><br/><br/>
       <li> Whether you offer dining, cabins, camping or a true hotel setup for your housing insurance remains similar. Consider getting business and personal property coverage to help keep your wallet safe from rebuilding all of those properties if the occasion came up. This is a very important insurance policy to maintain and start as many guest ranches are farther from towns and therefore if an unfortunate occurrence came across, such as a damaging hailstorm, a tornado, or a wildfire, help may be too late to save those properties. Another insurance option that some companies offer that could be helpful in areas similar to the examples prior is business income and extra expense. This policy offers coverage in the case of a financial loss in the business due to an unplanned, out of your control disaster.</li><br/><br/>
       <li> If your ranch has vehicles specific for your business consider getting commercial or business auto insurance to help cover those vehicles in relation to your business, allowing you to keep your personal family car and your work car separate. If you have tractors or off-road vehicles consider equipment breakdown insurance, to help cover those random breakdowns that will inevitably happen when they are least convenient.</li><br/><br/>
       <li> A final overview safe choice of insurance coverages at your guest ranch would be umbrella insurance. Umbrella insurance offers a coverage that provide additional liability limits above what commercial general liability and business auto policies can offer. Umbrella coverages can be seen as a safety blanket for the areas of your ranch needing insurance that don’t always fit a grouped policy.</li><br/><br/>
       <li> Whether you are a small guest ranch that offers some horseback riding and some camping, or a large-scale corporation with many elements working off of each other, all of these insurance packages should help keep your mind, your clients and your company safe from downfall, allowing you to feel in control no matter what is out of control.`,
        footerLable: ``,
        footerTitle: ``,
        footerLink: ``,
        contentList: []
    },
    {
        id: 5,
        name: 'Helpful Tips for Student Drivers',
        date: 'July 26, 2021',
        image: '/images/HelpfulTipsforStudentDrivers.webp',
        tag: 'Auto, Safety',
        tag1: 'auto, driving, safety, teen, teen driver, tips',
        tagLine: 'Becoming a new driver in the community can be fun, but it is also a huge responsibility that needs to be taken seriously for yourself and those around you.',
        description: 'Everyone ends up here at some point in their adolescent life, people are starting to get off the bus and into cars, peers around you are no longer accepting rides from mom and dad. Simply put, your friends have cars and freedom. Every teenager craves freedom and the ability to leave the house in control of where you are going and the vehicle that takes you there. Becoming a new driver in the community can be fun certainly, but it is also a huge responsibility that needs to be taken seriously for yourself and those around you both in and out of your vehicle. There are a few tips that can help you maintain that safety on the road while still allowing you to go about your newfound freedom.',
        footerLable: ``,
        footerTitle: ``,
        footerLink: ``,
        contentList: [
            {
                title: `Understanding the Rules of the Road`,
                subTitle: `A great way to understand the rules of the road is to enroll in a local drivers ed course for student drivers. This option can often be found through your local school district or your local DMV. Drivers education courses can help make you a safer, more rounded driver. Often, it can also help you receive discounts on your insurance. Talk to your local agent today.`
            },
            {
                title: `Phones and Texting`,
                subTitle: `The next step is to avoid distractions. There is no true set of rules as people are all so different from each other, but there are the legal rules. Don’t be on your phone when driving. The people on the other end of the phone can wait until you can park your car or get to your planned destination. Not only is cellphone use while driving dangerous, but if texting, also illegal. Being caught texting and driving could lead to a ticket, which leads to higher insurance prices, raising your rates. Edgar Snyder and Associates Law firm found that approximately 660,000 drivers attempt to use their phones while behind the wheel of their car. The National Safety Counsel reports that cell phone use while driving leads to 1.6 million crashes each year and that nearly 390,000 injuries occur each year from accidents in relation to texting while driving. In fact, texting while driving creates the likelihood that crashing is 6 times more likely to cause a car crash than driving drunk. Did you know that traveling at 55mph, you can travel across a football field in 5 seconds? Did you know it takes around 5 seconds to answer a text while driving? Meaning you can drive across a football field before your eyes are back on the road. Teen drivers are 4 times more likely to get into an accident for phone related reasons than adults, according to Edgar Snyder’s law firm. So put the phones to the side while you drive moving forward.`
            },
            {
                title: `Distractions While on the Road`,
                subTitle: `When it comes to music, know yourself. Know what tunes will cause higher distractions than others and go with the less distracting songs for you. Drive Safely Driving School suggestions making your driving playlist before you leave your destination so that you don’t have to change the music while you drive. Purchasing a hands-free driving aid, such as attaching your phone to the Bluetooth of the car for calls or placing your phone on a hands-free platform can help with answering calls or changing songs without having to take your eyes off the road or touch your device. Always keep those eyes moving! There is always going to be something happening in front, behind and beside you. This sounds intimidating, but with time and practice it will become second nature to look in your mirrors and check around you as you drive. Keeping eye movement is important because it keeps the brain active and keeps vision fresh, as keeping your eyes in the same place for long narrows your vision, as your eyes grow tired.
                </br> </br>
                Don’t follow close behind other drivers. Ever. Otherwise known as tailgating, following close behind other drivers can hinder the ability to stop in time when the car in front of you needs to stop or slow down. Following too closely behind could lead in an accident that will require insurance to take part in helping you repair your vehicle and the vehicle of those who the crash was with. This could lead to higher insurance prices and additional car repair prices, along with possible legal fees. The driver in front on you may also become agitated with such a close driver behind them. Respect yourself and others and offer the space on the road that is created for safety.
                </br> </br>
                Different driving scenarios can lead to more stressful driving, more distracted driving, and more mindless driving. Some examples would be heavy traffic, driving with people or pets, and driving an often-repeated path or open roads. When driving in heavy traffic locations, often cities, stress can rise and the chance of encountering more angry drivers around you skyrockets. Prepare for mental stress and more hazards and road rage when driving in a city. When driving with multiple people or pets in the car, you are far more likely to be distracted while driving because you are most likely also interacting and entertaining. While it feels like it, the brain can’t multitask as much as it seems, and singing along to loud music while laughing with your friends can be a hazard on the road if too much is going on around you. Driving on the same route often or driving in open roads with small amounts of traffic can lead to mindless driving because once you put your car in cruise your mind may wonder because the immediate challenges of the road drop in number. You should stay just as aware as driving in populated areas though, as unexpected dangers can occur on those open roads and a prepared mind is a safe mind.`
            },
            {
                title: `Practice Defensive Driving`,
                subTitle: `Practice being a defensive driver by planning ahead for the unexpected, being able to control the speed, being prepared to react to others, never expect drivers to do what you think they are going to do, and always respect the other drivers on the roadway. Remember when you climb into a vehicle to always be aware of changes in the road conditions, and the weather conditions for the day. By practicing the defensive driver techniques, you will be able to avoid dangers on the road, while keeping a respect for the other drivers on the road.`
            },
            {
                title: `Checking Your Blind Spots`,
                subTitle: `Check your blind spot manually before changing or entering a new lane. Many cars have blind spots that happen to be in passing zones of vehicles, the best way to avoid these blind spots becoming hazards is to turn your neck to manually check that spot. Of course, don’t spend too much time doing so, as you also have to check the traffic in front of you as well. The comforting news is that many vehicles are being upgraded to check these spots for you and help you be more aware of the areas around your vehicle.`
            },
            {
                title: `Understanding Your Vehicle`,
                subTitle: `Know your vehicle. Vehicles can carry you a long way if you take care of them, but just like athletes they need regular maintenance to keep them covering those distances for you. Do you need to know all the answers to every breakdown? No. You should know a few simple things to help you get from point a to point b. Some of those things are replacing your windshield wipers, knowing how to check and refill your vehicle fluids, checking your tire pressure, checking your headlights and taillights, knowing the schedule of basic car maintenance, such as how many miles between oil changes, understanding your shock and strut systems, being aware of your air filter, having an emergency roadside kit, having a tow rope, understanding the health of your breaks, and having the number of a good mechanic that you trust. Knowing these aspects of your car can help you understand how to handle new noises in your car that aren’t supposed to be there, and help you get to the mechanic and talk to the mechanic about the issue of your car when there is one.`
            },
            {
                title: `Get Out and Drive`,
                subTitle: `Hitting the open road can be exciting and freeing, but always be cautious, get educated, stay away from the distractions, use your head and of course practice! By using these skills and many more that you will learn in your growing experience, your chances of keeping yourself safe and those around you when driving will increase drastically. Enjoy that newfound freedom and drive safe!`
            },

        ]
    },
    {
        id: 6,
        name: 'Hail Preperations for your Home',
        date: 'August 6, 2021',
        image: '/images/HailPreperationsforyourHome.webp',
        tag: 'Home, Safety, Seasonal',
        tag1: 'hail, safety, storm, tips',
        tagLine: 'Summer storms that produce hail can pop up with little warning. Is your home and property ready if a hail storm should strike?',
        description: `Have you ever had a day where you start with a beautiful sunny day, as you drive to work the sun is shining, the clouds are spread evenly, and the skies are clear? You get to work and there is a small pang in your heart because you can’t help but think about all the fun things you could be doing in the brilliance of outside? As normal, you start your day at work and then you decide to take your break. You walk outside, wanting to take advantage of the beautiful day you left outside, but now it’s overcast and windy. The skies are dark, and the clouds are bright and heavy. Anxiety fills you up as you think thoughts of: Did I leave the windows open? Are the animals outside? Have I trimmed that big tree outside lately? Is my car remotely covered? Is it going to come in sideways or direct? How big are these cubes going to fall? These are just a sample of the thoughts that flood the mind of someone when hail strikes quickly.
</br></br>
        According to the National Severe Storms Laboratory, Nebraska, Colorado, and Wyoming usually have the highest number of hailstorms in the United States each year. These states meet in the area known as “hail alley” and average seven to nine hail days per year. If you live in “hail alley” providing care and coverages for your home and auto, along with simply maintaining your home for the hail season is incredibly important.
        </br></br>
        When considering insurance for hail, most homeowners' insurance policies will cover hail damage. If for some reason your homeowners' policy doesn’t cover hail, speak with your local agent, and discuss coverages that are available to you to cover hail damage. With auto insurance, full coverage insurance will cover damages, usually included with a small deductible. Having your home insured is a safety measure that shouldn’t be ignored, but of course there are methods of home maintenance that encourage a longer span of time between the need to use your insurance policies.`,
        footerLable: ``,
        footerTitle: ``,
        footerLink: ``,
        contentList: [
            {
                title: `Roof`,
                subTitle: `The first action is to ensure that your roof is in good condition. When necessary, missing sections of the roof due to past storms, leaks and cracks should be replaced and repaired as soon as possible. Steep sloped roofs, on average, experience less damage to hailstorms than low-sloped roofs, as there is less direct space to tear up when the hail lands.`
            },
            {
                title: `Windows and Siding`,
                subTitle: `If you have skylights on your home, consider selecting impact-rated skylights that meet FM approved standards, also known as large missile impact ratings. This important because large sized hail can be detrimental to glass that is easy to hit from falling impacts. Contractors approve of this suggestion, along with promoting fiber-cement siding where the aluminum and vinyl siding would otherwise be used, because it is more resilient to hail damage.`
            },
            {
                title: `Home and Vehicles`,
                subTitle: `When home, always remember to go around your home, checking that all drapes, blinds, and window shades are closed for safety. Of course, always make sure doors and windows are closed, as well. If there is time before the storm truly hits, try to hide your car the best that you can. If you have a garage, bring the cars into the garage until you are sure that the storm has passed. If you don’t have full coverage for your car, try parking it under coverage provided by nature or overhangs of your property. One of the most used methods of nature’s coverage would be the trees near your home. If you have covers for your car, a blanket or sheet, you can always cover your car with that to help the body of your car, breaking the impact.
<br/><br/>
                Hail is dangerous, and in many scenarios hits fast. Thankfully, it doesn’t usually last long in the span of time. While it can’t be avoided completely, as an act of nature, there are actions you can take to help prevent damage to your home and vehicle, and insurance coverage options that can help make those repairs when the storm simply overpowers your prevention. Always remember to check with your local insurance agent to review the coverages that are provided in your policies and to ensure that you have all that you need lined up for when nature packs up an icy punch.`
            },
        ]
    }
]
export default data;